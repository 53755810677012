import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
//Vue.config.devtools = true

const store = new Vuex.Store({
  state: {
    // 数据存储
    // 通过{{ $store.state}} 访问
    user: JSON.parse( window.localStorage.getItem('user'|| null)),
    websocketMessage:'',
    count: 0,
  },
  getters: {
  },
  mutations: {
    increment(state) {
      state.count++;
    },
    // 指定操作方式 只能同步
    setUser (state,payload) {
      state.user = JSON.parse(payload);
      // 将 playload 数据添加到本地存储中
      window.localStorage.setItem('user', payload) 
    },
    setWebsocketMessage (state,message) {
      state.websocketMessage=message
    },
  },
  actions: {
    // 提交mutations 完成异步功能
  },
  modules: {
  }
})
export default store
