import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


// 引入路由中所需要的组件功能
// import Login from '@/views/login/loginPage.vue'
// import Layout from '@/views/layout/layoutPage.vue'
// import Home from '@/views/home/homePage.vue'
// import Enter from '@/views/enter/enterPage.vue'


// 路由规则 
const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login/loginPage.vue"),
  },
  {
    path: '/',
    component: () => import(/* webpackChunName: '/' */'@/views/layout/layoutPage.vue'),
    // 直接设置给一级路由，则内部子路由都需要认证
    meta: {
      requiresAuth:true
    },
    redirect: "/manage",
    children: [


      {
        path: '/manage',
        name: 'manage',
        component: () => import(/* webpackChunName: 'history' */'@/views/manage/manage.vue'),
        redirect:"/login",
        children:[
          {path:'/manage/home',name:'home', 
            component: () => import(/* webpackChunName: 'history' */'@/views/manage/components/home.vue')},
          {path:'/manage/Main',name:'main',
          component: () => import(/* webpackChunName: 'history' */'@/views/manage/components/Main.vue')},
          {path:'/manage/warn', name:'warn',
          component: () => import(/* webpackChunName: 'history' */'@/views/manage/components/warn.vue')},
          {path:'/manage/User',name:'User',
          component: () => import(/* webpackChunName: 'history' */'@/views/manage/components/User.vue')},
          {path:'/manage/Distribute', 
          component: () => import(/* webpackChunName: 'history' */'@/views/manage/components/Distribute.vue')},
          {path:'/manage/control',name:'control',
          component: () => import(/* webpackChunName: 'history' */'@/views/manage/components/control.vue')},
          {path:'/manage/control1',name:'control1',
          component: () => import(/* webpackChunName: 'history' */'@/views/manage/components/control1.vue')},
          {path:'/manage/Subset',name:'control1',
          component: () => import(/* webpackChunName: 'history' */'@/views/manage/components/Subset.vue')},
          {path:'/manage/Subset1',name:'control11',
            component: () => import(/* webpackChunName: 'history' */'@/views/manage/components/set.vue')},
            {path:'/manage/11',name:'11',
              component: () => import(/* webpackChunName: 'history' */'@/views/manage/1.vue')},
          ] 
      },


    ]
  },

]

const router = new VueRouter({
  routes
})

router.beforeEach((to,from,next)=>{
  // 验证 to 路由是否需要身份验证

const token = window.sessionStorage.getItem("token");
var body = document.body
if (token) {
  if(to.path=='/manage/home'){
    body.style.overflow='hidden'
  }else{
    body.style.overflow='auto'
  }

  if (to.path === '/login'){
  next({ path: '/manage/User' })
 }else{
  next()
  }
}else if(to.path === '/login'){

  next()
}else{

  next({ path: '/login' })
}
  //  console.log(to,from)
})
export default router
