import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import store from './store'
import * as echarts from 'echarts'
import 'echarts-gl'
Vue.prototype.$echarts = echarts

import ElementUI from 'element-ui'
// 引入自己的全局样式文件
import './styles/index.scss'


import './main.css'

// import draggable from 'vuedraggable';
// Vue.use(draggable);
Vue.use(Vuex)
Vue.use(ElementUI)

//Vue.prototype.$baseURL = "http://60.12.1.126:1020"
Vue.prototype.$baseURL = "https://server.hzxingwaixing.com"
Vue.config.productionTip = false
Vue.config.devtools=true
Vue.prototype.$bus = new Vue()




Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === 'printReportShowQuery') {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);
        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);
        // 派发对象
        window.dispatchEvent(newStorageEvent)
      }
    }
    return storage.setItem(key, newVal);
  }
}
//const app = createApp(App);



// Vue.directive('draggable',{
//   bind(el,binding,vnode){
//     console.log(el);
//   }
// })




new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
