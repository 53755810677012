<template>
  <div id="app">
   <!-- <h1>东茗乡小程序后台管理系统</h1> -->
    <router-view/>
  </div>
</template>

<script>

import Vue from 'vue'
export default {
  name: 'App',

  data(){
    return{

    }
  },
  created() {
//   Vue.config.errorHandler = function (err, vm, info) {
//   console.error('Vue Error:', err);
//   console.error('Component:', vm);
//   console.error('Error Info:', info);
// }
// Vue.config.errorHandler=(error,vm,info)=>{
//   console.info('errorHandler---',error,vm,info);
//     }
}


}
// import DevicePixelRatio from './utils/devicePixelRatio.js'
// export default {
//     create() {
//       new DevicePixelRatio().init()
//       const keyCodeMap = {
//     // 91: true, // command
//     61: true,
//     107: true, // 数字键盘 +
//     109: true, // 数字键盘 -
//     173: true, // 火狐 - 号
//     187: true, // +
//     189: true, // -
// };
// // 覆盖[ctrl]command + [+]/[-]
// document.onkeydown = function (event) {
// 	//ratio = window.outerWidth / window.innerWidth;
//     const e = event || window.event;
//     const ctrlKey = e.ctrlKey || e.metaKey;
//     if (ctrlKey && keyCodeMap[e.keyCode]) {
//         e.preventDefault();
//     } else if (e.detail) { // Firefox
//         event.returnValue = false;
//     }
// };
 
// // 覆盖鼠标滚轮
// document.addEventListener('wheel', (e) => {
//     if (e.ctrlKey|| e.metaKey) {
//         if (e.deltaY < 0) {
// 			document.getElementsByTagName('body')[0].style.zoom=0.8;
//             e.preventDefault();
//             return false;
//         }
//         if (e.deltaY > 0) {
// 			document.getElementsByTagName('body')[0].style.zoom=0.8;
//             e.preventDefault();
//             return false;
//         }
//     }
// }, { passive: false });


    // }



  
  // created() {
  //   window.addEventListener(
  //     "mousewheel",
  //     function (event) {
  //       if (event.ctrlKey === true || event.metaKey) {
  //         event.preventDefault();
  //       }
  //     },
  //     { passive: false }
  //   );

  //   //firefox
  //   window.addEventListener(
  //     "DOMMouseScroll",
  //     function (event) {
  //       if (event.ctrlKey === true || event.metaKey) {
  //         event.preventDefault();
  //       }
  //     },
  //     { passive: false }
  //   );
  // },
// }
// import request from '@/utils/request'

// request({
//   method: 'GET',
//   url: 'http://49.235.106.165:8085/accommodation/hotel'
// }).then(res => {
//   console.log(res)
// })

// export default {
//   name: 'App'
// }
</script>
<style lang="scss">
  
</style>
